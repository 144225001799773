import img_agroindustria from '../assets/img/agroindustria.jpg';
import img_educacao from '../assets/img/educacao.jpg';
import img_saude from '../assets/img/saude.jpg';
import img_energia from '../assets/img/energia.jpg';


export default function SetoresInvest() {
  return (
    <div className="container" data-aos="fade-up">
      <section id="portfolio" className="portfolio sections-bg">
      <div className="section-header">
          <h2>Áreas de Intervenção</h2>
          <p className="text-center">
            Atuamos em setores de alto impacto Social, Económico e Financeiro, nomeadamente:
          </p>
        </div>
        <div className="row gy-4 portfolio-container p-4">
          <div className="col-xl-3 col-md-3 portfolio-item filter-app">
            <div className="portfolio-wrap">
              <span data-gallery="portfolio-gallery-app">
                <img src={img_agroindustria} className="img-fluid" alt="" style={{height:'370px', width: '100%'}} />
              </span>
              <div className="portfolio-info">
                <h3>Agroindústria</h3>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-3 portfolio-item filter-app">
            <div className="portfolio-wrap">
              <span data-gallery="portfolio-gallery-app">
                <img src={img_educacao} className="img-fluid" alt="" style={{height:'370px', width: '100%'}} />
              </span>
              <div className="portfolio-info">
                <h3>Educação</h3>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-3 portfolio-item filter-app">
            <div className="portfolio-wrap">
              <span data-gallery="portfolio-gallery-app">
                <img src={img_saude} className="img-fluid" alt="" style={{height:'370px', width: '100%'}} />
              </span>
              <div className="portfolio-info">
                <h3>Saúde</h3>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-3 portfolio-item filter-app">
            <div className="portfolio-wrap">
              <span data-gallery="portfolio-gallery-app">
                <img src={img_energia} className="img-fluid" alt="" style={{height:'370px', width: '100%'}} />
              </span>
              <div className="portfolio-info">
                <h3>Energia</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
