import About from "../pages/About";
import StatsCounter from "./StatsCounter";
import Instrumentos from "../pages/Instrumentos";
import Contact from "../pages/Contact";
import SectionHero from "./SectionHero";
import Header from "./Header";
import SetoresInvest from "../pages/SetoresInvest";

export default function Main() {
  return (
    <>
      <About />
      <SetoresInvest />
      <StatsCounter />
      <Instrumentos />
      <Contact />
    </>
  );
}
