import { Link } from "react-router-dom";

export default function Page404(){
    return(
        <>
        <div>


<div className="breadcrumbs">
    <div className="page-header d-flex align-items-center"  >
        <div className="container position-relative">
            <div className="row d-flex justify-content-center">
                <div className="col-lg-6 text-center">
                    <h2>ERROR, Pagina nao encontrada</h2>
                </div>
            </div>
        </div>
    </div>
    <nav>
        <div className="container">
            <ol>
                <li><Link to="/">Home</Link></li>
            </ol>
        </div>
    </nav>
</div>

<section className="sample-page">
    <div className="container" data-aos="fade-up">

        <p>
            Upsss, Pagina nao encontrada!
        </p>

    </div>
</section>

</div>
        </>
    )
}