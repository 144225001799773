import { Link } from "react-router-dom";
import about from '../assets/img/home_img2.jpg';


export default function InvestCapital() {
    return (
        <>
            <div>
                <div className="breadcrumbs">
                    <nav>
                        <div className="container">
                            <ol>
                                <li><Link to="/">Início</Link></li>
                                <li>Investimento em capital </li>
                            </ol>
                        </div>
                    </nav>
                </div>

                <section className="sample-page">
                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                            <div className="col-lg-6">
                                <img src={about} className="img-fluid rounded-4 mb-4" alt="" />
                            </div>
                            <div className="col-lg-6">
                                <div className="ps-0 ps-lg-5">
                                    <p>
                                        Através deste instrumento, a <strong>PROPANA</strong> Capital Investment participa, 
                                        não apenas no capital social das startups e PME com forte potencial de 
                                        crescimento e de impacto impacto economico, social e ambiental, 
                                        bem como na gestão e assessoria das mesmas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}