export default function SectionHero() {
    return (
        <>
            <section id="top" className="hero">
                <div className="container position-relative">
                    <div className="row gy-5 pt-5" data-aos="fade-up" >
                        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start textinvest pt-5">
                            <h2>O teu sucesso é a nossa conquista!</h2>
                            <div className="d-flex justify-content-center justify-content-lg-start pt-5">
                                <a href="#instrumentos" className="btn-get-started">Saber mais</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}