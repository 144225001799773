import about from "../assets/img/home_img1.jpg";
import vision from "../assets/img/vision.jpg";
import values from "../assets/img/values.jpg";



export default function About() {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Sobre nós</h2>
          <p>
            <strong>PROPANA Capital Investment</strong> é um fundo de private
            equity cuja ambição é ajudar a satisfazer as necessidades de
            crescimento das PME de elevado potencial de crescimento,
            permitindo-as participar de forma eficaz e sustentável no processo
            de desenvolvimento económico da Guiné-Bissau.
          </p>
        </div>
        <div className="row gy-4">
          <div className="col-lg-6">
            <img src={about} className="img-fluid rounded-4 mb-4" alt="" />
          </div>
          <div className="col lg-6">
            <div className="ps-0 ps-lg-5" >
              <h3>Objetivo</h3>
              <p>
                O nosso <strong>Objetivo</strong> é de se posicionar como
                parceiro de referência no capital de risco da África Lusófona,
                desenvolvendo estratégias diferenciadoras de crescimento,
                competitividade e capacidade de internacionalização para cada
                empresa em que investimos.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <h3>Visão</h3>
            <p>
              Ambicionamos ser a instituição líder em capital de risco e de
              consultoria estratégica para as empresas África Lusófona e um
              parceiro de confiança à qual todos desejam se associar, fazer
              negócios e indicar clientes, devido ao nosso melhor desempenho.
            </p>
          </div>
          <div className="col lg-6">
            <div className="ps-0 ps-lg-5">
              <img src={vision} className="img-fluid rounded-4" alt="" />
            </div>
          </div>
          <div className="col-lg-6 mt-5">
            <img src={values} className="img-fluid rounded-4 mb-4" alt="" />
          </div>
          <div className="col lg-6">
            <div className="mt-4 ps-lg-5 " >
              <h3>Valores</h3>
              <p>
                A prossecução da nossa visão é orientada por um conjunto de
                valores que suportam a nossa cultura e reforçam a nossa
                identidade. Valores esses que são : a <strong>Confiança</strong>, a <strong>Excelência</strong>, o
                <strong> Profissionalismo</strong> e o <strong>Compromisso</strong>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
