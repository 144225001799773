import logo from '../assets/img/logo_propana.svg';
 
export default function Header() {
    return (
        <>
            <header  className="headers d-flex align-items-center"  >
            <div className="container-fluid container-xl d-flex align-items-center justify-content-between conttransparent">
                <a href="#top" className="logo d-flex align-items-center">
                   <img src={logo} alt="" />
                   <h1>PROPANA Invest</h1>
                </a>
                <nav className= "navbar" id="navbar">
                    <ul>
                        <li><a href="#top">Início</a></li>
                        <li><a href="#about">Sobre nós</a></li>
                        <li><a href="#instrumentos">Instrumentos</a></li>
                        <li><a href="#contact">Contactos</a></li>
                    </ul>
                </nav>
                <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
                <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
                </div>
            </header>
        </>
    )
}