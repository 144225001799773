import { Link } from "react-router-dom";
import about from "../assets/img/investquasec.jpg";

export default function QuaseCapital() {
  return (
    <>
      <div>
        <div className="breadcrumbs">
          <nav>
            <div className="container">
              <ol>
                <li>
                  <Link to="/">Início</Link>
                </li>
                <li>Investimento em quase capital</li>
              </ol>
            </div>
          </nav>
        </div>

        <section className="sample-page">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              <div className="col-lg-6">
                <img src={about} className="img-fluid rounded-4 mb-4" alt="" />
              </div>
              <div className="col-lg-6">
                <div className="ps-0 ps-lg-5">
                  <p>
                    A <strong>PROPANA</strong> recorre a esse instrumento quando a opções de
                    financiamento através de participação no capital social não
                    são possíveis, tendo em conta os limites de exposição e/ou a
                    intenção de não diluir as partes dos accionistas
                    maioritários . Neste caso, por um lado, o retorno do
                    investimento é variável, dependendo da performance da
                    empresa beneficiária e, por outro lado, a PROPANA assume uma
                    posição intermediaria entre os credores e os sócios da
                    empresa.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
