import { Link } from "react-router-dom";
import about from '../assets/img/credito.jpeg';

export default function Emprestimo() {
    return (

        <>

            <div>


                <div className="breadcrumbs">
                    <nav>
                        <div className="container">
                            <ol>
                                <li><Link to="/">Início</Link></li>
                                <li>Crédito</li>
                            </ol>
                        </div>
                    </nav>
                </div>

                <section className="sample-page">
                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                            <div className="col-lg-6">
                                <img src={about} className="img-fluid rounded-4 mb-4" alt=""   />
                            </div>
                            <div className="col-lg-6">
                                <div className="ps-0 ps-lg-5">
                                    <p>
                                        O instrumento crédito é utilizado para o financiamento à curto, médio e longo prazo de projetos de
                                        investimento economicamente e financeiramente viáveis. Os créditos de <strong>PROPANA</strong> são geralmente cobertos de
                                        colateriais (activos fisicos e/ou garantia financeira).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}