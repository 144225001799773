export default function TopBar() {
  return (
    <>
      <section id="topbar" className="topbar d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope d-flex align-items-center">
              <a href="mailto:info@propana-invest.com">
                info@propana-invest.com
              </a>
            </i>
          </div>
          <div className="social-links d-none d-md-flex align-items-center">
            <a
              href="https://www.facebook.com/profile.php?id=100035369420183"
              className="facebook"
              target="_blank"
            >
              <i className="bi bi-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/propana_investment?igsh=MWJwdDZ2dzhvYWkyMg=="
              className="instagram"
            >
              <i className="bi bi-instagram"></i>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
