import { Link } from "react-router-dom";

export default function Terms() {
    return (
        <>
            <div>
                <div className="breadcrumbs">
                    <nav>
                        <div className="container">
                            <ol>
                                <li><Link to="/">Início</Link></li>
                                <li>Termos de utilização</li>
                            </ol>
                        </div>
                    </nav>
                </div>

                <section className="sample-page">
                    <div className="container" data-aos="fade-up">
                        <p>
                           
                        </p>
                    </div>
                </section>
            </div>
        </>
    )
}