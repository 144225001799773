import stats from "../assets/img/stats-img.jpg";
export default function StatsCounter() {
  return (
    <>
      <section id="stats-counter" className="stats-counter">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4 align-items-center">
            <div className="col-lg-6">
              <img src={stats} alt="" className="img-fluid" />
            </div>

            <div className="col-lg-6">
              <div className="stats-item d-flex align-items-center">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="7"
                  data-purecounter-duration="1"
                  className="purecounter"
                ></span>
                <p>
                  <strong>Projetos em Portfólio</strong>
                </p>
              </div>

              <div className="stats-item d-flex align-items-center">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="5"
                  data-purecounter-duration="1"
                  className="purecounter"
                >
                </span>
                <p>
                  <strong>Investidores</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
