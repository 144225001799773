import { Link } from "react-router-dom";

export default function Services() {
  return (
    <div className="container" data-aos="fade-up">
      <section id="instrumentos" className="services sections-bg">
        <div className="section-header">
          <h2>Instrumentos</h2>
          <p className="p-4">
            As intervenções do fundo realizam-se principalmente sob a forma de
            capital, quase-capital e empréstimos de médio e longo prazo. A
            oferta de financiamento é acompanhada de apoio na formulação e
            execução de estratégias de crescimento das pequenas e médias
            empresas.
          </p>
        </div>
        <div className="row gy-4 p-4" data-aos-delay="100">
          <div className="col-lg-4 col-md-6">
            <div className="service-item  position-relative">
              <div className="icon">
                <i className="bi bi-bank"></i>
              </div>
              <h3>Investimento em Capital de Risco</h3>
              <p>
                Participamos não apenas no capital social das startups e PME com forte
                potencial de crescimento e de impacto economico, social e
                ambiental...
              </p>
              <Link to="/invest-capital" className="readmore stretched-link">
                Saber mais
                <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-cash-coin"></i>
              </div>
              <h3>Investimento em Quasi-Capital</h3>
              <p>
                Recorremos a esse instrumento quando as opções de financiamento
                através de participação no capital social ou de crédito não são
                possíveis...
              </p>
              <Link to="/quase-capital" className="readmore stretched-link">
                Saber mais <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-activity"></i>
              </div>
              <h3>Consultoria Financeira</h3>
              <p>
                Oferecemos serviços de consultoria financeira e estratégica no
                âmbito de elaboração de projectos de investimento, realização de
                estudos de mercado...
              </p>
              <Link to="/consultoria" className="readmore stretched-link">
                Saber mais <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
