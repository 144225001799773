import TopBar from "./components/TopBar";
import Header from "./components/Header";
import SectionHero from "./components/SectionHero";
import Footer from "./components/Footer";
import Consultoria from "./pages/Consultoria";
import Credito from "./pages/Credito";
import InvestCapital from "./pages/InvestCapital"
import QuaseCapital from "./pages/QuaseCapital";
import Page404 from "./components/Page404";
import Main from "./components/Main";
import '../src/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../src/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../src/assets/vendor/aos/aos.css';
import '../src/assets/vendor/glightbox/css/glightbox.min.css';
import '../src/assets/vendor/swiper/swiper-bundle.min.css';
import '../src/assets/css/main.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <TopBar/>
        <Header />
        <SectionHero />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/consultoria" element={<Consultoria />} />
          <Route path="/credito" element={<Credito />} />
          <Route path="/invest-capital" element={<InvestCapital />} />
          <Route path="/quase-capital" element={<QuaseCapital />} />
          <Route path="/terms" element={<Terms/>}/>
          <Route path="/policy" element={<Policy/>}/>
          <Route path="/*" element={<Page404/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
