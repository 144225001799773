import { useState } from "react";

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

 const handleContact = () => {
     
      const myHeaders = new Headers();
      myHeaders.append("x-client-id", "U2FsdGVkX18PMYiTblRzFdGeLh2VnKjRO+MF5Fe5xXka81oVaVCyhAICKQweWMfe");
      myHeaders.append("app", "ND");
      myHeaders.append("Content-Type", "application/json");
      
      const data = JSON.stringify({
        "name": name,
        "email": email,
        "subject": subject,
        "message": message
      });
      
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: data,
      };
      
      fetch("https://api.nantoidigital.com/api/v1/send-email", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          setSuccess(true);
          setError(false);
          clearForm();
        })
        .catch((error) => {
          setSuccess(false);
          setError(true);
          clearForm();
          console.error(error);
        }); 
    }


    const clearForm = () => {
      setEmail('');
      setName('');
      setSubject('');
      setMessage('');
    }

  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Contactos</h2>
          <p className="p-4">
            Pronto para transformar a sua empresa? Entre em contacto connosco.
            Estamos aqui para ouvir as suas necessidades, discutir as suas metas
            e iniciar uma parceria que leve a sua empresa à excelência
            tecnológica.{" "}
          </p>
        </div>

        <div className="row gx-lg-0 gy-4">
          <div className="col-lg-4">
            <div className="info-container d-flex flex-column align-items-center justify-content-center">
              <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h4>Localização:</h4>
                  <p>Plack 2, Guiné-Bissau</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h4>Email:</h4>
                  <p>info@propana-invest.com</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8">
            <form
              id="form"
              name="form"
              role="form"
              className="php-email-form"
            >
              <div className="row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="O seu nome"
                    required
                    value={name}
                    onChange={(e)=>setName(e.target.value)}
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="O seu email"
                    required
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Assunto"
                  required
                  value={subject}
                  onChange={(e)=>setSubject(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  name="message"
                  id="message"
                  rows="7"
                  placeholder="Mensagem"
                  required
                  value={message}
                  onChange={(e)=>setMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message">
                  {error}
                  <p>
                    Não foi possível enviar a sua mensagem, por favor, tente novamente.
                  </p>
                </div>
                <div className="sent-message">
                  {success}
                  <p>
                    A sua mensagem foi enviado com sucesso. Agradecemos o seu
                    contacto!
                  </p>
                </div>
              </div>
              <div className="text-center">
                <button type="button" onClick={handleContact}>Enviar mensagem</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
