import { Link } from "react-router-dom";
import about from '../assets/img/consultoria.jpg';


export default function Consultoria() {
    return (
        <>
            <div>
                <div className="breadcrumbs">
                    <nav>
                        <div className="container">
                            <ol>
                                <li><Link to="/">Início</Link></li>
                                <li>Consultoria financeira </li>
                            </ol>
                        </div>
                    </nav>
                </div>

                <section className="sample-page">
                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                            <div className="col-lg-6">
                                <img src={about} className="img-fluid rounded-4 mb-4" alt="" height={500} width={500}  />
                            </div>
                            <div className="col-lg-6">
                                <div className="ps-0 ps-lg-5">
                                    <p>
                                        A <strong>PROPANA</strong> oferece serviços de consultoria financeira e estratégica no âmbito de elaboração de projectos
                                        de investimento, realização de estudos de mercado, elaboração de planos estratégicos, mobilização de fundos
                                        e estruturação de financiamento.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>

    )
}