import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer id="footer" className="footer">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <a href="index.html" className="logo d-flex align-items-center">
                <span>PROPANA Capital Investment</span>
              </a>
              <p>O teu sucesso é a nossa conquista!</p>
              <div className="social-links d-flex mt-4">
                <a
                  href="https://www.facebook.com/profile.php?id=100035369420183"
                  className="facebook"
                  target="_blank"
                >
                  <i className="bi bi-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/propana_investment?igsh=MWJwdDZ2dzhvYWkyMg=="
                  className="instagram"
                  target="_blank"
                >
                  <i className="bi bi-instagram"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Utilizadores</h4>
              <ul>
                <li>
                  <Link to="/terms">Termos de utilização</Link>
                </li>
                <li>
                  <Link to="/policy">Política de privacidade</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contacte-nos</h4>
              <p>
                <strong>Email:</strong> info@propana-invest.com
                <br />
              </p>
            </div>
          </div>
        </div>

        <div className="container pt-5">
          <div className="row gx-5" id="credits-section">
            <div className="col">
              &copy;{" "}
              <strong>
                <span>PROPANA Capital Investment</span>
              </strong>{" "}
              2024. Todos os direitos reservados.
            </div>
            <div className="col text-end">
              Powered by:
              <strong>
                <span className="credits"><Link to='https://www.nantoidigital.com' target="_blank">Nantoi Digital</Link></span>
              </strong>
              .
            </div>
          </div>
        </div>
      </footer>

      <a
        href="#"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}
